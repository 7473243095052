<template>
  <div class="wrap">
    <div class="box">
      <div class="top ">
        <div class="img-box" v-if="m.video" @click="openVideo(m.video)">
          <el-image class="img video-img" :src="rImg(m.images[0])" fit="cover">
            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
          </el-image>
        </div>
        <el-carousel v-else class="img-box" trigger="click" height="300px">
          <el-carousel-item v-for="(item,index) in m.images" :key="index">
            <el-image class="img" :src="rImg(item)" fit="cover" :preview-src-list="rBigImg(m.images)">
              <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
            </el-image>
          </el-carousel-item>
        </el-carousel>
        <div class="content">
          <h3 class="title">{{ m.content }}</h3>
          <p class="time">{{ m.create_time }}</p>
          <div class="tag-box" v-if="m.flag">
            <div class="tag">
              <span class="ellipsis-1 tag-name">#{{ m.flag.content }}</span>
              <span>· ￥{{ m.flag.price }}挑战金</span>
            </div>
          </div>
          <div class="user" v-if="m.user">
            <img :src="rImg(m.user.head_100)" :onerror="txImg" class="tx" alt="" />
            <span class="name ellipsis-1">{{ m.user.name }}</span>
          </div>
          <div class="icon area nopc" v-if="m.address && m.flag && m.flag.type == 1">
            <i class="iconfont icon-zhiyuandidian6"></i>
            <span>{{ m.address }}</span>
          </div>
          <div class="flex-bt">
            <div class="icon-box">
              <div class="icon area pc" v-if="m.address && m.flag && m.flag.type == 1">
                <i class="iconfont icon-zhiyuandidian6"></i>
                <span>{{ m.address }}</span>
              </div>
              <div class="icon">
                <i class="iconfont icon-rili"></i>
                <span>{{ m.day }}/{{ m.flag? m.flag.days : 0}}天</span>
              </div>
              <div class="icon link" @click="input.show = 1">
                <i class="iconfont icon-xiaoxi"></i>
                <span>{{ m.comments }}</span>
              </div>
              <div class="icon link" :class="{ active: m.is_like }" @click.stop="zanFlag()">
                <i class="iconfont icon-dianzan"></i>
                <span>{{ m.likes }}</span>
              </div>
            </div>
            <el-button round class="ping-btn" @click="input.show = !input.show">{{ input.show ? '收起' : '评论' }}</el-button>
          </div>
        </div>
      </div>
      <div class="bottom flex-c">
        <el-button @click="setWG()" v-if="m.flag && !m.flag.status" round class="app-btn" :class="{ ready: m.superviseInfo }">{{ !m.superviseInfo ? '围观分钱' : '已围观' }}</el-button>
        <el-button v-else-if="m.superviseInfo && m.superviseInfo.state == 1" round class="app-btn">分得{{ m.superviseInfo.score }}积分</el-button>
        <el-button v-else round class="app-btn ready">不可围观</el-button>
      </div>
    </div>
    <div class="input-box box" v-show="input.show">
      <el-input type="textarea" maxlength="50" :show-word-limit="true" resize="none" rows="5" placeholder="友好评论,说说你的想法" v-model="textarea"></el-input>
      <div style="text-align: right;"><el-button round class="ping-btn" @click="setPing">确定</el-button></div>
    </div>
    <h3 class="box-title">相关评论</h3>
    <div class="box">
      <pList :list="list"></pList>
      <p v-if="list.length>10" style="color: #aaa;">更多评论请前往APP查看</p>
    </div>
  </div>
</template>

<script>
import pList from "@components/common/comment";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    pList,
  },
  data() {
    return {
      id: "",
      m: {},
      list: [],
      textarea: "",
      input: {
        show: 0,
      },
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
    this.id = this.$route.query.id;
    if (!this.id) {
      this._home();
      return;
    }
    this.getData();
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {
    // 获取详情
    async getData() {
      let { data } = await this.$y_ajax("api/Flag/flagsInfoul", {
        flags_id: this.id,
      });
      this.m = data;
      this.getComment(1);
    },
    // 获取评论列表
    async getComment(page) {
      let param = {
        flags_id: this.id,
      };
      param.page = page || 1;
      let { list } = await this.$y_list("api/Flag/comments", [], param);
      this.list = list;
    },
    // 点赞
    async zanFlag() {
      let ret = await this.$y_ajax("api/Flag/like", { flags_id: this.id });
      this.$y_msg(ret.msg);
      if (ret.code == 200) {
        let is_like = this.m.is_like ? false : true;
        let likes = this.m.likes;
        likes = is_like ? likes + 1 : likes - 1;
        this.m.is_like = is_like;
        this.m.likes = likes;
      }
    },
    // 围观
    async setWG() {
      if (this.m.superviseInfo) return;
      let ret = await this.$y_ajax("api/Flag/supervise", { flag_id: this.id });
      this.$y_msg(ret.msg);
      if (ret.code == 200) {
        this.m.superviseInfo = true;
      }
    },
    // 评论
    async setPing() {
      if (!this.textarea) {
        this.$y_msg("请输入评论");
        return;
      }
      let { code, msg } = await this.$y_ajax("api/Flag/comment", {
        flags_id: this.id,
        content: this.textarea,
      });
      this.$y_msg(msg);
      if (code == 200) {
        let user = this.$y_getKey("userInfo");
        let obj = {
          content: this.textarea,
          user_id: user.id,
          flags_id: this.id,
          time: "刚刚",
          user: {
            head_portrait: user.head_portrait,
            head_100: user.head_portrait,
            id: user.id,
            self_slogan: user.self_slogan,
            name: user.name,
          },
        };
        this.list.unshift(obj);
        this.textarea = "";
        this.m.comments++;
      }
    },
  },
  computed: {
    // 计算属性
    ...mapGetters(["s_getUser"]),
  },
  watch: {
    // 监听
    s_getUser(n) {
      this.getData(1);
    },
  },
};
</script>

<style lang="less" scoped>
@import "@assets/less/des.less";
.box .top .img-box {
  margin-right: 20px;
  margin-top: 0;
}
</style>
